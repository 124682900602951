import {McGod, McMenu, McMenuItem} from '@miticon-ui/mc-core';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { ICON_DROP_DOWN } from '../svg_icons/icon-drop-down';
import { ICON_DROP_UP } from '../svg_icons/icon-drop-up';
import {ICON_ARROW_DOWN_GREY} from '../svg_icons/icon-arrow-down-grey';
import {ICON_ARROW_DOWN_WHITE} from '../svg_icons/icon-arrow-down-white';

@Component({

  selector: 'mc-menu',
  templateUrl: './mc-menu.component.html',
})
export class McMenuComponent implements OnInit {

  // isCollapsed = true;
  isItemSelected = false;
  @Input() isNewMenu: boolean;
  @Input() public menu!: McMenu;
  @Input() isCollapsed!: boolean;
  @Output() onCollapse = new EventEmitter();
  dropDownIcon = ICON_DROP_DOWN;
  dropUpIcon = ICON_DROP_UP;
  arrowDownGreyIcon = ICON_ARROW_DOWN_GREY;
  arrowDownWhiteIcon = ICON_ARROW_DOWN_WHITE;

  activeLevel0Index = -1;
  activeLevel1Index = -1;
  activeLevel2Index = -1;

  constructor() {}

  ngOnInit() {
    this.updateActiveState(this.menu.menuItemList);
    this.getActiveIndexes();
  }

  public setActiveIndexes(index: number, parentMenuItemIndex: number, mainIndex: number): void {
      this.activeLevel0Index = mainIndex
      this.activeLevel1Index = index;
      this.activeLevel2Index = parentMenuItemIndex;
  }


  public toggleDropdownMenu(aMenuItem: McMenuItem, event: any) {
    this.isItemSelected = true;
    let ulElement;
    if(!this.isCollapsed) {

      ulElement = event.currentTarget.nextElementSibling;

      if (ulElement && ulElement.style.height === '0px') {
        ulElement.style.transition = 'height 0.5s';
        ulElement.style.height = this.calculateSubmenuHeight(aMenuItem);
        aMenuItem.isExpanded = true;
      } else {
        ulElement.style.height = '0px';
        aMenuItem.isExpanded = false;
      }
    }
    return true;
  }

  public calculateSubmenuHeight(aMenuItem: McMenuItem): string {
    let totalHeight = 0;

    aMenuItem.submenu.forEach(subMenuItem => {
      subMenuItem.permission.length > 0 ? subMenuItem.isDisplayed = McGod.userHasPermissions(subMenuItem.permission) : subMenuItem.isDisplayed = true;
      if (subMenuItem.isDisplayed) {
        this.isNewMenu ? totalHeight += 48 : totalHeight += 45;
        if (subMenuItem.submenu && subMenuItem.submenu.length > 0 && subMenuItem.isExpanded) {
          totalHeight += parseInt(this.calculateSubmenuHeight(subMenuItem), 10);
        }
      }
    });
    return `${totalHeight}px`;
  }

 public getBorderImage(aMenuItem: McMenuItem): string {
    const submenuCount = aMenuItem.submenu.length;
    const itemHeight = 100 / submenuCount;
    const start = this.activeLevel1Index * itemHeight;
    const end = start + itemHeight;

    if (this.activeLevel1Index < 0 || submenuCount === 0) {
      return `linear-gradient(to bottom, #D6D7E1 0%, #D6D7E1 100%) 1`;
    }

    return `linear-gradient(to bottom,
             #D6D7E1 0%,
             #D6D7E1 ${start}%,
             #333867 ${start}%,
             #333867 ${end}%,
             #D6D7E1 ${end}%,
             #D6D7E1 100%) 1`;
  }

  onSelectMenuItem(aMenuItem: McMenuItem) {
    if(!this.isCollapsed) {
      this.isItemSelected = true;
      this.menu.menuItemList.map(menuItem => {
        if(menuItem.name === aMenuItem.name) {
          menuItem.isActive = true;
        }
        menuItem.hasActiveChild = false;
      });
    }
  }

  isActive(menuItemRoute: string): boolean {
    const currentUrl = window.location.href.substring(window.location.href.indexOf("#") + 1);
    if(menuItemRoute === '/dashboard') {
      return currentUrl.includes(menuItemRoute);
    } else return menuItemRoute === currentUrl;
  }

  private getActiveIndexes(): void {
    this.menu.menuItemList.forEach((mainItem, mainIndex) => {
      if (!mainItem.submenu?.length) return;

      mainItem.submenu.forEach((subItem) => {
        if (!subItem.submenu?.length) return;

        const activeLevel2Index = this.getActiveLVL2Index(subItem.submenu);
        if (activeLevel2Index !== -1) {
          this.activeLevel1Index = activeLevel2Index;
          this.activeLevel0Index = mainIndex;
        }
      });

      const activeLevel1Index = this.getActiveLVL1Index(mainItem.submenu);
      if (activeLevel1Index !== -1) {
        this.activeLevel2Index = activeLevel1Index;
        this.activeLevel0Index = mainIndex;
      }
    });
  }

  private updateActiveState(menuItems: any[]): boolean {
    let foundActive = false;
    menuItems.forEach(item => {
      if (item.route && McGod.matchActiveRoute(item.route)) {
        item.isExpanded = true;
        foundActive = true;
      }
      if (item.submenu && item.submenu.length > 0) {
        const childActive = this.updateActiveState(item.submenu);
        if (childActive) {
          item.isExpanded = true;
          item.hasActiveChild = true;
          foundActive = true;
        }
      }
    });
    return foundActive;
  }

  private getActiveLVL2Index(submenu: any[]): number {
    return submenu.findIndex(item => McGod.matchActiveRoute(item.route));
  }

  private getActiveLVL1Index(submenu: any[]): number {
    return submenu.findIndex(item => McGod.matchActiveRoute(item.route) && item.isExpanded);
  }
}
