import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Observable, Subject} from 'rxjs';
import {Router} from '@angular/router';

/*Models*/
import {CreateAccount} from '../models/user.model';
import {McInitLogin, McUserLogin, VerifyTwoFaRequest} from '../models/auth.model';

/*Services*/
import {JwtHelperService} from '@auth0/angular-jwt';
import {McUtilityService} from './mc-utility.service';

@Injectable({
  providedIn: 'root'
})
export class McAuthService {

  private httpOptions;
  private apiUrl: string;
  private primaryLoginEndpoint = '/oauth/token';  // URL to web api

  private _isAuthenticated = false;
  private _token!: string;
  private tokenTimer: any;
  public authStatusListener = new Subject<boolean>();
  private JWThelper = new JwtHelperService();

  /*Constructor*/
  constructor(
    private http: HttpClient,
    private router: Router,
    private utility: McUtilityService,
    @Inject('environment') environment: any
  ) {

    /*Set API base url*/
    this.apiUrl = environment.apiUrl;
    this.httpOptions = environment.httpOptions(HttpHeaders, this.token);
  }

  /*Get token*/
  get token(): string {
    return this._token;
  }

  /*Set token*/
  set token(value: string) {
    this._token = value;
  }

  /*Check if user logged in*/
  get isAuthenticated(): boolean {
    return this._isAuthenticated;
  }

  /*Set authenticated status*/
  set isAuthenticated(value: boolean) {
    this._isAuthenticated = value;
  }

  /*Init login*/
  public initLogin(data: any) {
    const endpoint = this.apiUrl + this.primaryLoginEndpoint;
    return this.http.post<McInitLogin>(endpoint, data);
  }
  /*User login*/
  public login(data: any) {
    return this.http.post<McUserLogin>(`${this.apiUrl + this.primaryLoginEndpoint}`, data);
  }

  /*Auto auth user*/
  public autoAuthUser(): void {
    const authInformation = this.getAuthData();
    if (authInformation) {
      const now = new Date();
      const expiresIn = authInformation.expirationDate.getTime() - now.getTime();
      if (expiresIn > 0) {
        this.token = authInformation.token;
        this.isAuthenticated = true;
        this.setAuthTimer(expiresIn / 1000);
        this.authStatusListener.next(true);
      }
    }
  }

  /*User logout - clear local storage*/
  public logout(): void {
    localStorage.removeItem('mc-user');
    localStorage.removeItem('mc-access-token');
    localStorage.removeItem('mc-refresh-token');
    localStorage.removeItem('rememberMe');

    this.router.navigate(['/auth/login']);
  }

  /*Set auth timer and user is authenticated - call in login and autoAuthUser methods*/
  public setAuthTimer(duration: number): void {
    this.isAuthenticated = true;
    this.authStatusListener.next(true);
    this.tokenTimer = setTimeout(() => {
      this.logout();
    }, duration * 1000);
  }

  /*Save auth data - call in login method*/
  public saveAuthData(token: string, refreshToken: string, expirationDate: Date, userId: number): void {
    localStorage.setItem('user_id', userId.toString());
    localStorage.setItem('token', token);
    localStorage.setItem('refreshToken', refreshToken);
    localStorage.setItem('expiration', expirationDate.toISOString());
  }

  /*Get auth data*/
  public getAuthData() {
    const mcUser = localStorage.getItem('mc-user');
    // @ts-ignore
    const token = this.utility.isJson(JSON.parse(mcUser)) && JSON.parse(mcUser).access_token;
    // @ts-ignore
    const expirationDate = this.utility.isJson(JSON.parse(mcUser)) && JSON.parse(mcUser).expires_in;

    return {
      token,
      expirationDate: new Date(expirationDate)
    };
  }

  /*Get decoded token*/
  public getDecodedToken() {
    // @ts-ignore
    return this.JWThelper.decodeToken(localStorage.getItem('mc-access-token'));
  }

  /*Confirmation for new account*/
  public createAccount(data: CreateAccount) {
    const apiUrl = this.apiUrl + `/account/confirm`;
    return this.http.post<CreateAccount>(apiUrl, data, this.httpOptions);
  }

  /*Get user information*/
  public getUserInfo(id: number): Observable<any> {
    return this.http.get<any>(this.apiUrl + `/core/user/${id}`);
  }

  /*Get password policy for user*/
  public getPasswordPolicyData(uuid: any, isReset: boolean) {
    const apiUrl = this.apiUrl + `/core/user/${uuid}/strongest-password-policy-with-uuid?isResetPasswordForm=${isReset}`;
    return this.http.get<any>(apiUrl);
  }

  // Forgot password
  public forgotPassword(data: { username: string; }) {
    const apiUrl = `${this.apiUrl}/account/forgot-password`;
    return this.http.post<any>(apiUrl, data, this.httpOptions);
  }

  // Reset password
  public resetPassword(data: CreateAccount) {
    const api = `${this.apiUrl}/account/reset-password`;
    return this.http.post<any>(api, data, this.httpOptions);
  }
  // Get logged user info
  public getLoggedUserInfo() {
    // @ts-ignore
    return  JSON.parse(localStorage.getItem('mc-user'));
  }

  public verify2fa(data: VerifyTwoFaRequest): Observable<any> {
    const api = `${this.apiUrl}/account/verify-2fa`;
    return this.http.post<any>(api, data, this.httpOptions);
  }

  public setUseNewMenu(isNewMenu: boolean) {
    const api = this.apiUrl + `/core/user/set-use-new-ui`;
    return this.http.put<any>(api, isNewMenu, this.httpOptions);
  }
}
