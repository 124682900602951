import {Inject, Injectable} from '@angular/core';
import {HttpClient, HttpHeaders, HttpParams} from '@angular/common/http';
import {BehaviorSubject, Observable, Subject} from 'rxjs';

// Models
import {PageMcEntityResponseDto, McEntityResponseDto, McEntityRequest, EntityAttributes, McUserEntitiesList, CustomAttributeDto} from '../models/entity.model';
import {McUtilityService} from './mc-utility.service';
import {SortCriteriaList} from '../_core/sort-criteria-list.model';

@Injectable({
  providedIn: 'root'
})
export class McEntityService {

  /*Public variables*/
  public _selectedEntity = new BehaviorSubject({
    selected: false,
    id: 0
  });
  public selectedEntity = this._selectedEntity.asObservable();

  /*Selected entity path*/
  public _selectedEntityPath = new BehaviorSubject([]);
  public selectedEntityPath = this._selectedEntityPath.asObservable();

  /*Private variables*/
  private apiUrl!: string;
  private token;
  private httpOptions;
  private mcUser = localStorage.getItem('mc-user');


  // Constructor
  constructor(
    private httpClient: HttpClient,
    private utilityService: McUtilityService,
    @Inject('environment') environment: any) {

    // @ts-ignore
    this.token = JSON.parse(localStorage.getItem('mc-access-token'))

    /*Set API base url*/
    if (environment) {
      this.apiUrl = environment.apiUrl;
      this.httpOptions = environment.httpOptions(HttpHeaders, this.token);
    }
  }

  /*Get selected entity*/
  public getSelectedEntity() {
    return this._selectedEntity;
  }

  /*Set selected entity*/
  public setSelectedEntity(entity: { selected: boolean; id: number; }) {
    this._selectedEntity.next(entity);
  }

  /*Set selected entity path*/
  public setSelectedEntityPath(entityPath: any[]) {
    // @ts-ignore
    this._selectedEntityPath.next(entityPath);
  }

  /*Get user entities from DB*/
  public getEntitiesFromDB() {
    return this.httpClient.get<McUserEntitiesList>(`${this.apiUrl}/core/user/entity`);
  }

  // Get all entities from DB
  getAllMcEntities(page: number, perPage: number): Observable<PageMcEntityResponseDto> {
    return this.httpClient.get<PageMcEntityResponseDto>(this.apiUrl + `/mc-entity?page=${page}&per_page=${perPage}`);
  }

  // Get all children of entities
  findAllChildMcEntities(id: number): Observable<McEntityResponseDto []> {
    return this.httpClient.get<McEntityResponseDto []>
    (this.apiUrl + `/mc-entity/${id}/child-entities`);
  }

  // Get all parents of entities
  findAllParentMcEntities(id: number): Observable<McEntityResponseDto[]> {
    return this.httpClient.get<McEntityResponseDto[]>
    (this.apiUrl + `/mc-entity/${id}/parent-entities`);
  }

  // Get all auth of entities
  getAllAuthMcEntities(id: number): Observable<PageMcEntityResponseDto> {
    return this.httpClient.get<PageMcEntityResponseDto>
    (this.apiUrl + `/mc-system-user/${id}/entity`);
  }

  // Search entities by name
  /*todo: backend need to change API call*/
  searchMcEntitiesByName(name: string): Observable<PageMcEntityResponseDto> {
    return this.httpClient.get<PageMcEntityResponseDto>(this.apiUrl + `/mc-entity/search?name=${name}`);
  }

  /*Get entity basic info from DB*/
  getMcEntityById(id: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/${id}`);
  }

  // todo: check with Mihajlo if we need this method.
  /*  findRolesForMcEntity(id: number): Observable<RoleResponseDto []> {
      return this.httpClient.get<RoleResponseDto []>(this.env.apiUrl + `/mc-entity/${id}/roles`, {headers: this.headers});
    }*/

  // Create new entity
  public createMcEntity(entity: McEntityRequest): Observable<McEntityResponseDto> {
    return this.httpClient.post<McEntityResponseDto>(this.apiUrl + `/mc-entity`, entity);
  }

  // Create new entity
  public createNewEntity(body: {
    isVisibleToChildren: boolean;
    mailPrefix: string; /*Get password policy for logged entity*/
    active: any;
    imageData: any;
    name: any;
    mailDomain: any;
    abbreviation: any;
    webDomain: any;
    description: any;
    ipWhitelist: any;
    phone: any;
    bankAccount: null;
    fax: any;
    euVat: any;
    address: any;
    impressum: any;
    legalRepresentative: any;
    legalRepresentativeContact: any;
    extCreditorId: any;
    countryId: any;
    bankTransactionEntityName: any;
    priceListId: number;
    studioOwner: any;
    studioOwnerAddress: any;
    studioOwnerPostalCode: any;
    studioOwnerCity: any;
    studioManager: any;
    courtInCharge: any;
    customAttributes: any[];
    passwordPolicy: {
      inheritFromParent: any;
      maxRetryCount: any;
      minPassHistory: any;
      lockoutTime: any;
      minDigits: any;
      minPassLength: any;
      minSpecialChars: any;
      passMaxDays: any;
      minLowercaseLetters: any;
      minDiffChars: any;
      minUppercaseLetter: any;
    };
  }) {
    return this.httpClient.post<any>(this.apiUrl + `/core/entity`, body);
  }

  /*Get parent for logged entity*/
  public getParentEntity(id: any) {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/${id}/parent`);
  }

  /*Get all entities for logged entity*/
  public getAllEntitiesNames() {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/name/all`);
  }

  /*Get all web-domains*/
  public getAllWebDomains() {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/web-domain/all`);
  }

  // Get inheritable attr
  public getInheritableAttributes(id: number): Observable<EntityAttributes[]> {
    return this.httpClient.get<EntityAttributes[]>(this.apiUrl + `/mc-entity/${id}/inheritable-attributes`);
  }

  // Get Parent password policy
  getParentPasswordPolicy(id: number): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mc-entity/${id}/password-policy`);
  }

  /*Get password policy for logged entity*/
  public getPasswordPolicyData() {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/password-policy`);
  }

  /*Get password policy for currentEntity*/
  public getEntityPasswordPolicyData(id: any) {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/${id}/password-policy`);
  }

  // Edit basic info for logged entity
  editBasicInfo(body: any) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity`, body);
  }

  // Edit basic info for current entity
  editCurrentEntityBasicInfo(body: {
    mailPrefix: string;
    active: any;
    imageData: any;
    name: any;
    mailDomain: any;
    abbreviation: any;
    webDomain: any;
    description: any;
    ipWhitelist: any;
    phone: any;
    bankAccount: any;
    fax: any;
    euVat: any;
    address: any;
    impressum: any;
    legalRepresentative: any;
    legalRepresentativeContact: any;
    factoringEnableFlg: boolean;
  }, id: string) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity/${id}`, body);
  }

  // Edit attributes for logged entity
  editEntityAttributes(body: CustomAttributeDto[]) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity/custom-attribute`, body);
  }

  // Edit attributes for current entity
  editCurrentEntityAttributes(body: CustomAttributeDto[], id: any) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity/${id}/custom-attribute`, body);
  }

  // Edit password policy for logged entity
  editPasswordPolicy(body: any) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity/password-policy`, body);
  }

  // Edit password policy for current entity
  editCurrentEntityPasswordPolicy(body: {
    inheritFromParent: any;
    maxRetryCount: any;
    minPassHistory: any;
    lockoutTime: any;
    minDigits: any;
    minPassLength: any;
    minSpecialChars: any;
    passMaxDays: any;
    minLowercaseLetters: any;
    minDiffChars: any;
    minUppercaseLetter: any;
  }, id: any) {
    return this.httpClient.put<any>(this.apiUrl + `/core/entity/${id}/password-policy`, body);
  }

  // Get custom attributes data
  getAttributes() {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/custom-attribute`);
  }

  // Get parent attributes data for logged entity
  getParentAttributes() {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/parent/custom-attribute`);
  }

  // Get attributes data for logged entity
  getEntityAttributes() {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/custom-attribute`);
  }

  // Get attributes data for current Entity
  getEntityAttributesById(id: any) {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/${id}/custom-attribute`);
  }

  // Check Vat number
  vatNumberValidation(vat: any) {
    return this.httpClient.post<any>(this.apiUrl + `/core/entity/vat/check`, vat);
  }

  // Filter child companies
  filterChildCompanies(id: number, name: string, active: string): Observable<McEntityResponseDto[]> {
    const params = new HttpParams()
      .set('name', name)
      .set('active', active);
    return this.httpClient.get<McEntityResponseDto[]>(this.apiUrl + `/mc-entity/${id}/child-entities`, {
      headers: this.httpOptions.headers,
      params
    });
  }

  /*Get data for logged entity*/
  public getLoggedinEntity() {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity`);
  }

  /*Add super user with all permissions in all entites*/
  public addSuperUserWithAllPermissionsInAllEntities() {
    return this.httpClient.post<any>(`${this.apiUrl}/core/dev/fix`, null);
  }

  /*Fix consumer history*/
  public fixConsumerHistory() {
    return this.httpClient.post<any>(`${this.apiUrl}/core/dev/fix-consumer-history`, null);
  }

  /*Fix consumer history*/
  public fixTransactions() {
    return this.httpClient.post<any>(`${this.apiUrl}/core/dev/fix-transactions`, null);
  }

  public getWebDomainsForEntity(id: number) {
    return this.httpClient.get<any>(`${this.apiUrl}/core/entity/ancestor-web-domains/${id}`);
  }

  public getAllWhereParentEntityIsLoggedInEntity(aPageNo: number, aItemsPerPage: number, sortCriteria: SortCriteriaList): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/mcentity/v3/mcentity`
      + `/getallwhereparententityisloggedinentity?page=${aPageNo}&size=${aItemsPerPage}${sortCriteria.toUrlQueryString()}`, {
      headers: new HttpHeaders(
        {
          'Content-Type': 'application/json',
        })
    });

  }

  getTokenOnSelectEntity(id: string): Observable<any> {
    return this.httpClient.get<any>(this.apiUrl + `/core/entity/${id}/token`);
  }

  public storeParentEntityId(): void {
    this.getLoggedinEntity().subscribe((data) => {
      localStorage.setItem('parentEntityId', data.parentMcEntityId);
    });
  }
}
