import {Component, OnInit} from '@angular/core';
import {BaseDirective, McCurrencyService, McDashboardService, PmTransaction,} from '@miticon-ui/mc-core';
import {ICON_OVERDUE} from "../../../../assets/media/svg_icons/icon-overdue";
import {ICON_SENT_TO_BANK} from "../../../../assets/media/svg_icons/icon-sent-to-bank";
import {ICON_TO_BE_SENT} from "../../../../assets/media/svg_icons/icon-to-be-sent";
import Chart, {ChartConfiguration} from 'chart.js/auto';
import {TranslateService} from "@ngx-translate/core";
import {ICON_DROP_UP} from "../../../../assets/media/svg_icons/icon-drop-up";
import {ICON_DROP_DOWN} from "../../../../assets/media/svg_icons/icon-drop-down";
import {forkJoin} from "rxjs";
import moment from "moment";
import 'chart.js/auto';
import {FormBuilder, FormGroup} from "@angular/forms";
import {ICON_LIGHT_BULD} from "../../../../assets/media/svg_icons/icon-lightbulb";
import { MatDialog } from '@angular/material/dialog';
import { NewMenuDialogComponent } from '../../../mc-admin/mc-sidebar/new-menu-dialog/new-menu-dialog.component';
import { McSidebarMenuConfigService } from '../../../mc-admin/mc-sidebar/services/mc-sidebar-menu-config.service';

@Component({
  selector: 'lib-dashboard',
  templateUrl: './dashboard.component.html',
  styleUrls: ['./dashboard.component.scss']
})
export class DashboardComponent extends  BaseDirective implements OnInit {

  showLoader: boolean = true;
  form!: FormGroup;
  dateFrom: any = moment(new Date(new Date().getFullYear(), 0, 1)).format('YYYY-MM-DD');
  dateTo: any = moment(new Date(new Date().getFullYear(), 11, 31)).format('YYYY-MM-DD');
  currency: string;
  iconLightBulb = ICON_LIGHT_BULD;

  bankTransactions = [
    {
      label: "cc.dashboard.overdue-transactions",
      icon: ICON_OVERDUE,
      value: 0
    },
    {
      label: "cc.dashboard.transactions-sent-to-bank",
      icon: ICON_SENT_TO_BANK,
      value: 0
    },
    {
      label: "cc.dashboard.transactions-to-be-sent",
      icon: ICON_TO_BE_SENT,
      value: 0
    }
  ]

  //Matching payments chart
  chartMatchingPaymentsDataConfig!: any;
  canvasMatchingPayments: any;
  ctxMatchingPayments: any;
  chartMatchingPayment: any;
  machingPaymentData = {
    manuallyMatched: 0,
    automaticallyMatched: 0,
    consumerBalance: 0
  }

  //Dunning chart
  chartDunningDataConfig!: any;
  canvasDunning: any;
  ctxDunning: any;
  chartDunning: any;
  dunningData = {
    forDunning: 0,
    inDunning: 0,
    completedDunning: 0
  }

  //Inkasso chart
  chartInkassoDataConfig!: any;
  canvasInkasso: any;
  ctxInkasso: any;
  chartInkasso: any;
  inkassoData = {
    shouldGoToInkasso: 0,
    sendingToInkasso: 0,
    sentToInkasso: 0,
    returnedFromInkasso: 0
  }

  // Complaint dunning chart
  chartComplaintDunningDataConfig!: any;
  canvasComplaintDunning: any;
  ctxComplaintDunning: any;
  chartComplaintDunning: any;
  complaintDunningData = {
    consumers: 0,
    transactions: 0
  }

  // Complaint inkasso chart
  chartComplaintInkassoDataConfig!: any;
  canvasComplaintInkasso: any;
  ctxComplaintInkasso: any;
  chartComplaintInkasso: any;
  complaintInkassoData = {
    consumers: 0,
    transactions: 0
  }

  //Transaction statuses chart
  chartTransactionStatusesDataConfig!: ChartConfiguration;
  canvasTransactionStatuses: any;
  ctxTransactionStatuses: any;
  chartTransactionStatuses: any;
  transactionStatusesData = {
    accepted: 0,
    rejected: 0,
    new: 0,
    paid: 0,
    settled: 0,
    returned: 0,
    exported: 0,
    forDunning: 0,
    shouldGoToInkasso: 0,
    sendingToInkasso: 0,
    sentToInkasso: 0,
    returnedFromInkasso: 0
  }

  //Transactions amounts chart
  chartInstances: { [key: string]: any } = {};
  transactionsAmounts = [
    {  name: this.tS.instant("cc.eb-factoring.accepted"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_ACCEPTED },
    {  name: this.tS.instant("cc.eb-factoring.rejected"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_REJECTED },
    {  name: this.tS.instant("cc.common.view.new"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_NEW },
    {  name: this.tS.instant("cc.factoring.transactions.paid"), revenue: 0, percentage: 0,type: PmTransaction.STATUS_CD_PAID },
    {  name: this.tS.instant("cc.transaction.settled"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SETTLED },
    {  name: this.tS.instant("cc.factoring.transactions.returned"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_RETURNED },
    {  name: this.tS.instant("cc.eb-sepa-export.exported"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_EXPORTED },
    {  name: this.tS.instant("cc.factoring.transactions.for-dunning"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_FOR_DUNNING },
    {  name: this.tS.instant("cc.transactions.should-go-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO },
    {  name: this.tS.instant("cc.transactions.sending-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SENDING_TO_INKASSO },
    {  name: this.tS.instant("cc.transactions.sent-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SENT_TO_INKASSO},
    {  name: this.tS.instant("cc.transactions.returned-from-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO }
  ];

  totalMatchingPayment = 0;
  totalDunning = 0;
  totalInkasso = 0;

  //Statistics
  toggleStatisticsSelect: boolean = false;
  iconDropUp = ICON_DROP_UP;
  iconDropDown = ICON_DROP_DOWN;
  statisticsOptions = [
    {
      label: "cc.dashboard.bank-transactions",
      value: true
    },
    {
     label: "cc.transaction.matching-payments",
     value: true
    },
    {
      label: "cc.consumers.action.type.dunning",
      value: true
    },
    {
      label: "cc.dashboard.inkasso",
      value: true
    },
    {
      label: "cc.dashboard.consumer-complaints-and-related-tr",
      value: true
    },
    {
      label: "cc.dashboard.transaction-amounts",
      value: true
    },
    {
      label: "cc.dashboard.transaction-statuses",
      value: true
    }
  ]


  constructor(private dashboardService: McDashboardService,
              private mcCurrencyService: McCurrencyService,
              private tS: TranslateService,
              private fb: FormBuilder,
              private dialog: MatDialog,
              private menuConfigService: McSidebarMenuConfigService
  ) {
    super();
  }

  ngOnInit() {
    this.mcCurrencyService.getByEntity().subscribe((currency) => {
      if(currency) {
        switch(currency.code) {
          case 'EUR':
            this.currency = '€';
            break;
          case 'CHF':
            this.currency = 'fr.';
            break;
          case 'CZK':
            this.currency = 'Kč';
            break;
          default:
            this.currency = '';
        }
      }
      this.openNewMenuDialog()
    })
    this.form = this.fb.group({
      fromDate: [this.dateFrom],
      toDate: [this.dateTo]
    });

  }

  ngAfterViewInit() {
    this.loadData();
  }

  openNewMenuDialog(){
    if (history.state.useNewMenu===false && !this.menuConfigService.getMenuFlg()) {
      this.dialog.open(NewMenuDialogComponent, {
        width: '750px'
      }).afterClosed().subscribe(data => {
       this.menuConfigService.setNewMenuFlag(!!data)
      })
    }
  }

  loadData() {
    this.showLoader = true;
    const transactionsByStatuses$ = this.dashboardService.getTransactionsByStatuses(this.dateFrom, this.dateTo);
    const dunningStatuses$ = this.dashboardService.getDunningStatuses(this.dateFrom, this.dateTo);
    const transactionsAmounts$ = this.dashboardService.getTransactionsAmounts(this.dateFrom, this.dateTo);
    const matchingPayments$ = this.dashboardService.getMatchingPayments(this.dateFrom, this.dateTo);
    const consumersWithComplaintsAndTransactions$ = this.dashboardService.getConsumersWithComplaintsAndTransactions();
    forkJoin([transactionsByStatuses$, dunningStatuses$, transactionsAmounts$, matchingPayments$, consumersWithComplaintsAndTransactions$]).subscribe(([transactionsByStatuses, dunningStatuses, transactionsAmounts, matchingPayments, consumersWithComplaintsAndTransactions]) => {
      transactionsByStatuses.forEach((analytic: any) => this.setTransactionStatusData(analytic));
      this.totalInkasso = Object.values(this.inkassoData).reduce((sum, value) => sum + value, 0);
      this.createTransactionStatusesChart();
      this.createInkassoChart();

      dunningStatuses.forEach((data: any) => this.setDunningStatusData(data));
      this.createDunningChart();

      transactionsAmounts.forEach((analytic: any) => this.setTransactionAmountsData(analytic));
      this.createTransactionsAmountsCharts();

      matchingPayments.forEach((analytic: any) => this.setMatchingPaymentsData(analytic));
      this.createMatchingPaymentChart();

      this.setComplaintsData(consumersWithComplaintsAndTransactions);
      this.createComplaintsAndRelatedTransactionChart();

      this.showLoader = false;
    }, error => {
      this.showLoader = false;
    });
  }

  private setTransactionStatusData(analytic: any): void {
    switch (analytic.statusCd) {
      case PmTransaction.STATUS_CD_ACCEPTED:
        this.updateBankTransaction("cc.dashboard.transactions-to-be-sent", analytic.transactionCountForStatus);
        this.transactionStatusesData.accepted = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_REJECTED:
        this.transactionStatusesData.rejected = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_NEW:
        this.transactionStatusesData.new = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_PAID:
        this.transactionStatusesData.paid = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_SETTLED:
        this.transactionStatusesData.settled = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_RETURNED:
        this.updateBankTransaction("cc.dashboard.overdue-transactions", analytic.transactionCountForStatus);
        this.transactionStatusesData.returned = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_EXPORTED:
        this.updateBankTransaction("cc.dashboard.transactions-sent-to-bank", analytic.transactionCountForStatus);
        this.transactionStatusesData.exported = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_FOR_DUNNING:
        this.transactionStatusesData.forDunning = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO:
        this.updateBankTransaction("cc.dashboard.overdue-transactions", analytic.transactionCountForStatus);
        this.transactionStatusesData.shouldGoToInkasso = analytic.transactionCountForStatus;
        this.inkassoData.shouldGoToInkasso = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_SENDING_TO_INKASSO:
        this.updateBankTransaction("cc.dashboard.overdue-transactions", analytic.transactionCountForStatus);
        this.transactionStatusesData.sendingToInkasso = analytic.transactionCountForStatus;
        this.inkassoData.sendingToInkasso = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_SENT_TO_INKASSO:
        this.transactionStatusesData.sentToInkasso = analytic.transactionCountForStatus;
        this.inkassoData.sentToInkasso = analytic.transactionCountForStatus;
        break;
      case PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO:
        this.transactionStatusesData.returnedFromInkasso = analytic.transactionCountForStatus;
        this.inkassoData.returnedFromInkasso = analytic.transactionCountForStatus;
        break;
    }
  }

  private setDunningStatusData(data: any): void {
    switch (data.statusCd) {
      case PmTransaction.DUNNING_STATUS_CD_IN_DUNNING:
        this.dunningData.inDunning = data.transactionCountForStatus;
        break;
      case PmTransaction.DUNNING_STATUS_CD_DUNNING_COMPLETED:
        this.dunningData.completedDunning = data.transactionCountForStatus;
        break;
      case null:
        this.dunningData.forDunning = data.transactionCountForStatus;
        break;
    }
    this.totalDunning = data.totalTransactionsCount;
  }

  private setMatchingPaymentsData(data: any): void {
    switch (data.matchingType) {
      case 'CONSUMER_BALANCE':
        this.machingPaymentData.consumerBalance = data.countForType;
        break;
      case 'MANUAL':
        this.machingPaymentData.manuallyMatched = data.countForType;
        break;
      case 'AUTO':
        this.machingPaymentData.automaticallyMatched = data.countForType;
        break;
    }
    this.totalMatchingPayment = data.totalCount;
  }

  private setTransactionAmountsData(analytic: any): void {
    const amountData = this.transactionsAmounts.find((transaction) => transaction.type === analytic.statusCd);
    if (amountData) {
      amountData.revenue = analytic.transactionsAmountForStatus;
      amountData.percentage = Math.round((analytic.transactionsAmountForStatus / analytic.totalAmount) * 100);
    }
  }

  private setComplaintsData(consumersWithComplaintsAndTransactions: any) {
    this.complaintDunningData = {
      consumers: consumersWithComplaintsAndTransactions.countDunningDisabled,
      transactions: consumersWithComplaintsAndTransactions.countDunningWithTransaction
    }

    this.complaintInkassoData = {
      consumers: consumersWithComplaintsAndTransactions.countInkassoDisabled,
      transactions: consumersWithComplaintsAndTransactions.countInkassoWithTransaction
    }
  }

  private updateBankTransaction(label: string, value: number): void {
    const transaction = this.bankTransactions.find((transaction) => transaction.label === label);
    if (transaction) {
      transaction.value += value;
    }
  }

  createMatchingPaymentChart() {
    this.canvasMatchingPayments = document.getElementById('matchingPaymentsChart');
    this.ctxMatchingPayments = this.canvasMatchingPayments.getContext('2d');

    const matchingPaymentsChartData = {
      labels: [
        this.tS.instant("cc.dashboard.manually-matched"),
        this.tS.instant("cc.dashboard.automatically-matched"),
        this.tS.instant("cc.consumer.consumers-balance")
      ],
      datasets: [{
        data: [
          this.machingPaymentData.manuallyMatched,
          this.machingPaymentData.automaticallyMatched,
          this.machingPaymentData.consumerBalance
        ],
        backgroundColor: [ '#AEB2ED', '#53589B', '#333867' ],
        borderWidth: 2
      }]
    }

    this.chartMatchingPaymentsDataConfig = {
      type: 'doughnut',
      data: matchingPaymentsChartData,
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              font: {
                family: 'sans-serif',
                size: 12,
              }
            }
          }
        }
      }
    };
    this.chartMatchingPayment = new Chart(this.ctxMatchingPayments, this.chartMatchingPaymentsDataConfig);
  }

  createDunningChart() {
    this.canvasDunning = document.getElementById('dunningChart');
    this.ctxDunning = this.canvasDunning.getContext('2d');

    const dunningChartData = {
      labels: [
        this.tS.instant("cc.factoring.transactions.for-dunning"),
        this.tS.instant("cc.factoring.transactions.in-dunning"),
        this.tS.instant("cc.factoring.transactions.completed-dunning")
      ],
      datasets: [{
        data: [
          this.dunningData.forDunning,
          this.dunningData.inDunning,
          this.dunningData.completedDunning
        ],
        backgroundColor: [ '#9DDDED', '#5EABBE', '#378194' ],
        borderWidth: 2
      }]
    }

    this.chartDunningDataConfig = {
      type: 'doughnut',
      data: dunningChartData,
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              font: {
                family: 'sans-serif',
                size: 12,
              }
            }
          }
        }
      }
    };
    this.chartDunning = new Chart(this.ctxDunning, this.chartDunningDataConfig);
  }

  createInkassoChart() {
    this.canvasInkasso = document.getElementById('inkassoChart');
    this.ctxInkasso = this.canvasInkasso.getContext('2d');

    const inkassoChartData = {
      labels: [
        this.tS.instant("cc.transactions.should-go-to-inkasso"),
        this.tS.instant("cc.transactions.sending-to-inkasso"),
        this.tS.instant("cc.transactions.sent-to-inkasso"),
        this.tS.instant("cc.transactions.returned-from-inkasso")
      ],
      datasets: [{
        data: [
          this.inkassoData.shouldGoToInkasso,
          this.inkassoData.sendingToInkasso,
          this.inkassoData.sentToInkasso,
          this.inkassoData.returnedFromInkasso
        ],
        backgroundColor: [ '#FFB1D2', '#FB5B9E', '#D71969', '#990A46' ],
        borderWidth: 2
      }]
    }


    this.chartInkassoDataConfig = {
      type: 'doughnut',
      data: inkassoChartData,
      options: {
        cutout: 70,
        plugins: {
          legend: {
            display: true,
            position: 'right',
            labels: {
              usePointStyle: true,
              font: {
                family: 'sans-serif',
                size: 12,
              }
            }
          }
        }
      }
    };
    this.chartInkasso = new Chart(this.ctxInkasso, this.chartInkassoDataConfig);
  }

  createTransactionStatusesChart() {
    this.canvasTransactionStatuses = document.getElementById('chartTransactionStatuses');
    this.ctxTransactionStatuses = this.canvasTransactionStatuses.getContext('2d');

    let mem_gradient = this.ctxTransactionStatuses.createLinearGradient(0, 0, 510, 0);
    mem_gradient.addColorStop(0, '#53589B');
    mem_gradient.addColorStop(1, '#5EABBE');

    this.ctxTransactionStatuses.fillStyle = mem_gradient;
    this.ctxTransactionStatuses.fillRect(20, 20, 150, 100);
    const chartTransationStatusesData = {
      labels: [
        this.tS.instant("cc.eb-factoring.accepted"),
        this.tS.instant("cc.eb-factoring.rejected"),
        this.tS.instant("cc.common.view.new"),
        this.tS.instant("cc.factoring.transactions.paid"),
        this.tS.instant("cc.transaction.settled"),
        this.tS.instant("cc.factoring.transactions.returned"),
        this.tS.instant("cc.eb-sepa-export.exported"),
        this.tS.instant("cc.factoring.transactions.for-dunning"),
        this.tS.instant("cc.transactions.should-go-to-inkasso"),
        this.tS.instant("cc.transactions.sending-to-inkasso"),
        this.tS.instant("cc.transactions.sent-to-inkasso"),
        this.tS.instant("cc.transactions.returned-from-inkasso")
      ],
      datasets: [
        {
          backgroundColor: [mem_gradient, mem_gradient, mem_gradient, mem_gradient, mem_gradient],
          data: [
            this.transactionStatusesData.accepted,
            this.transactionStatusesData.rejected,
            this.transactionStatusesData.new,
            this.transactionStatusesData.paid,
            this.transactionStatusesData.settled,
            this.transactionStatusesData.returned,
            this.transactionStatusesData.exported,
            this.transactionStatusesData.forDunning,
            this.transactionStatusesData.shouldGoToInkasso,
            this.transactionStatusesData.sendingToInkasso,
            this.transactionStatusesData.sentToInkasso,
            this.transactionStatusesData.returnedFromInkasso
          ],
          barThickness: 20,
          barPercentage: 0.5,
          borderRadius: 10
        }
      ],
    };

    const chartTransactionStatusesOptions: any = {
      indexAxis: 'y',
      plugins: {
        legend: {
          display: false
        }
      }, scales: {
        x: {
          grid: {
            color: "rgba(0, 0, 0, 0)"
          },
          title: {
            display: true,
            text: this.tS.instant("cc.dashboard.number-of-transactions"),
            color: '#53589B',
            font: {
              size: 16
            }
          },
          ticks: {
            backdropColor: '#CCCCCC'
          }
        },
        y: {
          grid: {
            color: "rgba(0, 0, 0, 0)"
          },
          title: {
            display: true,
            text: this.tS.instant("cc.factoring.transactions.transaction-status"),
            color: '#53589B',
            font: {
              size: 16
            }
          },
          ticks: {
            backdropColor: '#CCCCCC'
          }
        },
      }
    }

    const backgroundColorPlugin = {
      id: 'background-color',
      beforeDatasetDraw: (chart: any, args: any, options: any) => {
        const ctx = chart.ctx;
        const yAxis = chart.scales.y;
        const dataset = chart.getDatasetMeta(args.index);
        const data = dataset.data;
        ctx.save();
        ctx.globalCompositeOperation = 'destination-over';
        ctx.fillStyle = '#EFEFF3';

        const maxDataHeight = Math.max(...data.map((dataPoint: any) => dataPoint.height));
        const maxDataWidth = Math.max(...data.map((dataPoint: any) => dataPoint.width));

        data.forEach((dataPoint: any) => {
          ctx.beginPath();
          ctx.moveTo(yAxis.right + 10, dataPoint.y - (maxDataHeight / 2));
          ctx.lineTo(yAxis.right + maxDataWidth - 10, dataPoint.y - (maxDataHeight / 2));
          ctx.arcTo(yAxis.right + maxDataWidth, dataPoint.y - (maxDataHeight / 2), yAxis.right + maxDataWidth, dataPoint.y - (maxDataHeight / 2) + 10, 10);
          ctx.lineTo(yAxis.right + maxDataWidth, dataPoint.y + (maxDataHeight / 2) - 10);
          ctx.arcTo(yAxis.right + maxDataWidth, dataPoint.y + (maxDataHeight / 2), yAxis.right + maxDataWidth - 10, dataPoint.y + (maxDataHeight / 2), 10);
          ctx.lineTo(yAxis.right + 10, dataPoint.y + (maxDataHeight / 2));
          ctx.arcTo(yAxis.right, dataPoint.y + (maxDataHeight / 2), yAxis.right, dataPoint.y + (maxDataHeight / 2) - 10, 0);
          ctx.lineTo(yAxis.right, dataPoint.y - (maxDataHeight / 2) + 10);
          ctx.arcTo(yAxis.right, dataPoint.y - (maxDataHeight / 2), yAxis.right + 10, dataPoint.y - (maxDataHeight / 2), 0);
          ctx.closePath();
          ctx.fill();
        });

        ctx.restore();
      }
    };


    this.chartTransactionStatusesDataConfig = {
      type: 'bar',
      data: chartTransationStatusesData,
      options: chartTransactionStatusesOptions,
      plugins: [backgroundColorPlugin]
    }

    this.chartTransactionStatuses = new Chart(this.ctxTransactionStatuses, this.chartTransactionStatusesDataConfig);
    this.showLoader = false;
  }

  createTransactionsAmountsCharts() {
    const container1 = document.getElementById('charts-container-1');
    const container2 = document.getElementById('charts-container-2');
    if (!container1 || !container2) return;

    this.transactionsAmounts.forEach((item: any, index: any) => {
      const id = `chart-${index + 1}`;
      const canvas = document.createElement('canvas');
      canvas.id = id;
      canvas.classList.add('mt-extra-large');

      if (index < 6) {
        container1.appendChild(canvas);
      } else {
        container2.appendChild(canvas);
      }

      this.createTransactionsAmountsChart(id, item);
    });
  }


  createTransactionsAmountsChart(canvasId: string, data: any) {
    let ctx: any = document.getElementById(canvasId) as HTMLCanvasElement | null;
    if (!ctx) return;

    this.chartInstances[canvasId] = new Chart(ctx, {
      type: 'doughnut',
      data: {
        labels: [data.name],
        datasets: [{
          data: [data.percentage, 100 - data.percentage],
          backgroundColor: ['#5EABBE', "#DFEEF2"],
          weight: 5,
          borderWidth: 0
        }]
      },
      options: {
        cutout: 50,
        plugins: {
          legend: {
            display: false
          }
        }
      }
    });
  }

  createComplaintsAndRelatedTransactionChart() {
    this.chartComplaintDunning = this.createComplaintsChart(
      "chartComplaintDunning",
      "legendComplaintDunning",
      this.canvasComplaintDunning,
      this.ctxComplaintDunning,
      this.chartComplaintDunningDataConfig,
      this.complaintDunningData,
      true);

    this.chartComplaintInkasso = this.createComplaintsChart(
      "chartComplaintInkasso",
      "legendComplaintInkasso",
      this.canvasComplaintInkasso,
      this.ctxComplaintInkasso,
      this.chartComplaintInkassoDataConfig,
      this.complaintInkassoData,
      false);
  }

  createComplaintsChart(elementId: string, legend: string, canvas: any, ctx: any, dataConfig: any, data: any, isDunning: boolean) {
    canvas = document.getElementById(elementId);
    ctx = canvas.getContext('2d');

    dataConfig = {
      type: 'bar',
      data: {
        labels: [
          isDunning
            ? this.tS.instant("cc.dashboard.consumers-without-dunning")
            : this.tS.instant("cc.dashboard.consumers-without-inkasso"),
        ],
        datasets: [
          {
            label:
              data.consumers + " " +
              (isDunning
              ? this.tS.instant("cc.dashboard.consumers-without-dunning")
              : this.tS.instant("cc.dashboard.consumers-without-inkasso")),
            backgroundColor: isDunning ? "#5EABBE" : "#D71969",
            data: [data.consumers],
            barThickness: 80,
          },
          {
            label:
              data.transactions + " " +
              (isDunning
              ? this.tS.instant("cc.dashboard.consumers-without-dunning.tr")
              : this.tS.instant("cc.dashboard.consumers-without-inkasso.tr")),
            backgroundColor: isDunning ? "#9DDDED" : "#FFB1D2",
            data: [data.transactions],
            barThickness: 80
          },
        ],
      },
      options: {
        scales: {
          x: {
            stacked: true,
            grid: {
              display: false,
            },
            ticks: {
              font: {
                size: 22
              },
            },
          },
          y: {
            stacked: true,
            ticks: {
              font: {
                size: 22
              },
            },
          },
        },
        indexAxis: 'y',
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          legend: {
            display: false
          },
          tooltip: {
            enabled: true,
            bodyFont: {
              size: 22
            },
            titleFont: {
              size: 22
            },
          },
          htmlLegend: {
            containerID: legend,
          },
        }
      },
      plugins: [
        {
          id: "htmlLegend",
          afterUpdate(chart: any) {
            const legendContainer = document.getElementById(legend);
            if (!legendContainer) return;

            legendContainer.innerHTML = "";

            const ul = document.createElement("ul");
            ul.style.listStyle = "none";
            ul.style.padding = "0";
            ul.style.margin = "0";

            chart.data.datasets.forEach((dataset: any, i: any) => {
              const li = document.createElement("li");
              li.style.display = "flex";
              li.style.alignItems = "center";
              li.style.marginBottom = "8px";
              li.style.fontSize = "14px";

              const box = document.createElement("span");
              box.style.display = "block";
              box.style.width = "15px";
              box.style.height = "15px";
              box.style.marginRight = "10px";
              box.style.borderRadius = "50%";
              box.style.backgroundColor = dataset.backgroundColor as string;

              const label = document.createTextNode(dataset.label);

              li.appendChild(box);
              li.appendChild(label);
              ul.appendChild(li);
            });

            legendContainer.appendChild(ul);
          },
        },
      ],
    };

    return new Chart(ctx, dataConfig);
  }

  checkVisibility(label: string) {
    return this.statisticsOptions.find(option => option.label === label)?.value;
  }

  onDateFrom(value: any) {
    if(this.form.get('fromDate')?.value != null ) {
      this.dateFrom = moment(this.form.get('fromDate')?.value).format('YYYY-MM-DD');
    }
  }

  onDateTo(value: any) {
    if(this.form.get('toDate')?.value != null) {
      this.dateTo = moment(this.form.get('toDate')?.value).format('YYYY-MM-DD');
      for (let id in this.chartInstances) {
        if (this.chartInstances[id]) {
          this.chartInstances[id].destroy();
        }
      }
      this.chartInstances = {};

      const containers = [document.getElementById('charts-container-1'), document.getElementById('charts-container-2')];
      containers.forEach(container => {
        if (container) {
          while (container.firstChild) {
            container.removeChild(container.firstChild);
          }
        }
      });

      this.chartTransactionStatuses.destroy();
      this.chartInkasso.destroy();
      this.chartDunning.destroy();
      this.chartComplaintDunning.destroy();
      this.chartComplaintInkasso.destroy();
      this.chartMatchingPayment.destroy();
      this.setDefaultData();
      this.loadData();
    }
  }

  setDefaultData() {
    this.bankTransactions = [
      {
        label: "cc.dashboard.overdue-transactions",
        icon: ICON_OVERDUE,
        value: 0
      },
      {
        label: "cc.dashboard.transactions-sent-to-bank",
        icon: ICON_SENT_TO_BANK,
        value: 0
      },
      {
        label: "cc.dashboard.transactions-to-be-sent",
        icon: ICON_TO_BE_SENT,
        value: 0
      }
    ]

    this.machingPaymentData = {
      manuallyMatched: 0,
      automaticallyMatched: 0,
      consumerBalance: 0
    }

    this.dunningData = {
      forDunning: 0,
      inDunning: 0,
      completedDunning: 0
    }

    this.inkassoData = {
      shouldGoToInkasso: 0,
      sendingToInkasso: 0,
      sentToInkasso: 0,
      returnedFromInkasso: 0
    }

    this.transactionStatusesData = {
      accepted: 0,
      rejected: 0,
      new: 0,
      paid: 0,
      settled: 0,
      returned: 0,
      exported: 0,
      forDunning: 0,
      shouldGoToInkasso: 0,
      sendingToInkasso: 0,
      sentToInkasso: 0,
      returnedFromInkasso: 0
    }

    this.transactionsAmounts = [
      {  name: this.tS.instant("cc.eb-factoring.accepted"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_ACCEPTED },
      {  name: this.tS.instant("cc.eb-factoring.rejected"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_REJECTED },
      {  name: this.tS.instant("cc.common.view.new"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_NEW },
      {  name: this.tS.instant("cc.factoring.transactions.paid"), revenue: 0, percentage: 0,type: PmTransaction.STATUS_CD_PAID },
      {  name: this.tS.instant("cc.transaction.settled"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SETTLED },
      {  name: this.tS.instant("cc.factoring.transactions.returned"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_RETURNED },
      {  name: this.tS.instant("cc.eb-sepa-export.exported"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_EXPORTED },
      {  name: this.tS.instant("cc.factoring.transactions.for-dunning"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_FOR_DUNNING },
      {  name: this.tS.instant("cc.transactions.should-go-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SHOULD_GO_TO_INKASSO },
      {  name: this.tS.instant("cc.transactions.sending-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SENDING_TO_INKASSO },
      {  name: this.tS.instant("cc.transactions.sent-to-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_SENT_TO_INKASSO},
      {  name: this.tS.instant("cc.transactions.returned-from-inkasso"), revenue: 0, percentage: 0, type: PmTransaction.STATUS_CD_RETURNED_FROM_INKASSO }
    ];

    this.complaintDunningData = {
      consumers: 0,
      transactions: 0
    };

    this.complaintInkassoData = {
      consumers: 0,
      transactions: 0
    };

    this.totalMatchingPayment = 0;
    this.totalDunning = 0;
    this.totalInkasso = 0;
  }
}
