import { inject, Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { McAuthService } from '@miticon-ui/mc-core';

@Injectable({
  providedIn: 'root'
})
export class McSidebarMenuConfigService {

  authService = inject(McAuthService);

  private storageNewMenuKey = 'newMenuFlag';
  private newMenuFlagSubject = new BehaviorSubject<boolean>(JSON.parse(localStorage.getItem(this.storageNewMenuKey) || 'false')
  );
  newMenuFlag$ = this.newMenuFlagSubject.asObservable();

  setNewMenuFlag(flag: boolean): void {
    flag ? this.authService.setUseNewMenu(flag).subscribe(() => { this.newMenuFlagSubject.next(flag); })
         : this.newMenuFlagSubject.next(flag);
    localStorage.setItem(this.storageNewMenuKey, JSON.stringify(flag));
  }

  setMenuFlag(flag: boolean): void {
    this.newMenuFlagSubject.next(flag);
  }

  getMenuFlg(){
    return this.newMenuFlagSubject.getValue();
  }

  constructor() { }
}
