import {Component, Inject, Input, OnDestroy} from '@angular/core';
import {Location} from '@angular/common';
import {NgbModal} from '@ng-bootstrap/ng-bootstrap';
import {ActivatedRoute, Router} from '@angular/router';
import {McAuthService, McUtilityService, McEntityService, McStateManagementService, McGod, EbFactoringService} from '@miticon-ui/mc-core';
import {HttpParams} from '@angular/common/http';
import {JwtHelperService} from "@auth0/angular-jwt";
import { ICON_HEADER_DROPDOWN } from '../../../../../assets/media/svg_icons/icon-header-dropdown';

@Component({
  selector: 'mc-select-entity',
  templateUrl: './mc-select-entity.component.html'
})
export class McSelectEntityComponent implements OnDestroy {

  alphabetSelect;
  welcomeImg: string;
  logo: string;
  searchEntityValue = '';
  filterEntityValue = '';
  entities: any[] = [];
  entitiesArray: any[] = [];
  entitiesSearchArray: any[] = [];
  show!: boolean;
  notifyMessageObj = {
     message: '',
    type: ''
  };
  entityList: any[] = [];

  entitySelectedSubscription!: any;
  modalReference!: any;
  paramsSubscription;
  openModalParamsSubscription!: any;
  loginApiCallSubscription!: any;
  entityIdFromUrl!: any;
  isModalOpen = false;
  iconHeaderDropdown = ICON_HEADER_DROPDOWN;

  @Input() selectedEntity!: any;
  @Input() openFromHeader!: any;

  constructor(private modalService: NgbModal,
              private router: Router,
              private location: Location,
              private activatedRoute: ActivatedRoute,
              private stateManagement: McStateManagementService,
              private authService: McAuthService,
              private utilityService: McUtilityService,
              private entityService: McEntityService,
              private factoringService: EbFactoringService,
              @Inject('designConfig') design: any,
              @Inject('utilityConfig') utility: any
  ) {

    this.paramsSubscription = this.activatedRoute.paramMap.subscribe(data => {
      this.entityIdFromUrl = data.get('id');
      const entityIdFromToken = this.utilityService.getPropertyFromToken('entity_id');

      if (!isNaN(this.entityIdFromUrl)) {
        this.entityIdFromUrl != entityIdFromToken && this.onSelectEntityById(this.entityIdFromUrl);
      } else {
        this.router.navigate(['/auth/login']);
        this.authService.logout();
      }
    });

    this.welcomeImg = design.welcomeImg;
    this.logo = design.logo;

    const alphabetArray = utility.alphabet
      .split('')
      .map((item: any) => {
        return {value: item, selected: false};
      });
    this.alphabetSelect = [{value: 'All', selected: true}, ...alphabetArray];
  }

  /*Open modal*/
  public openModal(content: any) {
    this.stateManagement.setOpenSelectEntityModal(true);

    this.stateManagement.listEntities.subscribe(entityList => {
      this.entityList = entityList;

      this.entityList.filter(item => {
        this.openModalParamsSubscription = this.activatedRoute.paramMap.subscribe(data => {
          const entityIdFromRoute = Number(data.get('id'));
          if (item.id === entityIdFromRoute) {
            item.selected = true;
          } else {
            item.selected = false;
          }
          return item;
        });
      });

      /*Create tree*/
      this.entities = this.tree(this.entityList);
      this.entitiesArray = this.entityList;
      this.entities.sort((a, b) => {
        const nameA = a.name.toUpperCase();
        const nameB = b.name.toUpperCase();
        return (nameA < nameB) ? -1 : (nameA > nameB) ? 1 : 0;
      });
    });

    /*Modal reference - used for close it*/
    this.modalReference = this.modalService.open(content, {
      centered: true,
      windowClass: 'mc-select-entity-modal',
      beforeDismiss: () => {
        this.entitiesArray.forEach((entity) => {
          entity.selected = false;
        });
        this.openModalParamsSubscription && this.openModalParamsSubscription.unsubscribe();
        this.entitySelectedSubscription && this.entitySelectedSubscription.unsubscribe();
        this.loginApiCallSubscription && this.loginApiCallSubscription.unsubscribe();
        this.isModalOpen = false;
        this.searchEntityValue = '';
        this.filterEntityValue = '';
        this.onSelectLetter(0);
        this.notifyMessageObj = Object.assign({}, {
          type: '',
          message: ''
        });
        return true;
      }
    });
    this.isModalOpen = true;

  }

  public onChangeSelectedEntity() {
    this.entitySelectedSubscription = this.entityService.selectedEntity.subscribe(entity => {

      if (entity.id !== 0) {
        this.selectedEntity = entity;
        localStorage.setItem('parentEntityId', this.selectedEntity.parentMcEntityId);
        const entities = this.entitiesArray.map(item => {
          item.selected = item.id === entity.id;
          return item;
        });
      }
    });
  }



  private tree(list: any) {
    const idAttr = 'id';
    const parentAttr = 'parentMcEntityId';
    const childrenAttr = 'children';
    const treeList: any[] = [];
    const lookup = {};

    list.map((obj: any, index: any) => {

      // @ts-ignore
      lookup[obj[idAttr]] = obj;
      obj[childrenAttr] = [];

      if (index === list.length - 1) {
        list.map((object: any) => {
          if (object[parentAttr]) {
            // @ts-ignore
            if (lookup[object[parentAttr]])
              { // @ts-ignore
                lookup[object[parentAttr]][childrenAttr].push(object);
              }
            else
              delete object[parentAttr];
          } else {
            treeList.push(object);
          }
        });
      }
    });

    return treeList;
  }

  public onSelectLetter(index: any): void {
    this.alphabetSelect.map((item, i) => {
      if (index === i) {
        item.selected = true;
        this.filterEntityValue = item.value;
        this.onFilterEntity();
      } else {
        item.selected = false;
      }
      return item;
    });
  }

  onSearchEntity(): void {
    if (this.searchEntityValue !== '') {
      this.filterEntityValue = '';
      this.alphabetSelect.forEach(item => item.selected = item.value === 'All');
      this.entitiesSearchArray = this.entityList.filter(item => {
        item.children = [];
        if (item.name.toLowerCase().search(this.searchEntityValue.toLowerCase()) >= 0) {
          return item;
        }
      });
      this.entities = this.entitiesSearchArray;
      this.setNotifyMessage();
    } else {
      this.entities = this.tree(this.entitiesArray);
      this.setNotifyMessage();
    }
  }

  onFilterEntity(): void {
    if (this.filterEntityValue !== '' && this.filterEntityValue !== 'All') {
      this.searchEntityValue = '';
      this.entitiesSearchArray = this.entitiesArray.filter(item => {
        item.children = [];
        const firstLetter = item.name.toLowerCase().charAt(0);
        if (this.filterEntityValue.toLowerCase() === firstLetter) {
          return item;
        }
      });
      this.entities = this.entitiesSearchArray;
      this.setNotifyMessage();
    } else {
      this.searchEntityValue = '';
      this.filterEntityValue = '';
      this.entities = this.tree(this.entitiesArray);
      this.setNotifyMessage();
    }
  }

  public onProceed(): void {

    if (this.selectedEntity && this.selectedEntity.selected) {

      this.onSelectEntityById(this.selectedEntity.id.toString());

    } else {
      this.notifyMessageObj = Object.assign({}, {
        message: 'Please select entity.',
        type: 'error'
      });
    }
  }

  setNotifyMessage() {
    if (this.entities.length === 0) {
      this.notifyMessageObj = Object.assign({}, {
        message: 'No results',
        type: 'error'
      });
    } else {
      this.notifyMessageObj = Object.assign({}, {
        message: '',
        type: ''
      });
    }
  }

  // @ts-ignore
  onSelectEntityById(id: string) {
    if (Number(id) === Number(this.utilityService.getPropertyFromToken("entity_id"))) {
      return null;
    }

    localStorage.setItem('mc-select-entity-modal-opened', JSON.stringify(true));

    /*const body = new HttpParams()
      .set('entity_id', id)
      // @ts-ignore
      .set('select_entity_token', JSON.parse(localStorage.getItem('mc-access-token')))
      .set('grant_type', 'select_entity');*/

    this.entityService.getTokenOnSelectEntity(id)
      .subscribe(response => {
        localStorage.setItem('mc-refresh-token', JSON.stringify(response.refresh_token));
        localStorage.setItem('mc-access-token', JSON.stringify(response.access_token));
        localStorage.setItem('mc-user', JSON.stringify(response));
        localStorage.removeItem('mc-select-entity-modal-opened');
        this.stateManagement.setIsSwitchedEntity(true);
        this.onSelectLetter(0);
        this.searchEntityValue = '';
        const currentRoute = this.location.path().split("/");
        let index: any;
        currentRoute.forEach((item, i) => {
          if (!isNaN(Number(item)) && i !== 0 && !index) {
            index = i;
          }
        });
        currentRoute[index] = id;
        this.isModalOpen && this.modalReference.close();
        McGod.getInstance().fireBroadcastTriggerLoginChanged();
        this.router.navigateByUrl(`/admin/entity/${id}/dashboard`);
        setTimeout(() => {
          window.location.reload();
        }, 1000);
      }, error => {
        localStorage.removeItem('mc-select-entity-modal-opened');
        this.notifyMessageObj = Object.assign({}, {
          message: error.error.error_description,
          type: 'error'
        });
      });
  }

   setOverflowClass() {
    return this.searchEntityValue === '' ? (this.filterEntityValue === '' ? 'overflow-auto' : null) : null;
  }

  ngOnDestroy(): void {
    this.loginApiCallSubscription && this.loginApiCallSubscription.unsubscribe();
    this.entitySelectedSubscription && this.entitySelectedSubscription.unsubscribe();
    this.paramsSubscription && this.paramsSubscription.unsubscribe();
    this.openModalParamsSubscription && this.openModalParamsSubscription.unsubscribe();
  }

}
